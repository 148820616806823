import React from "react";
import { graphql } from "gatsby";
import Post from "../components/post";

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    post(slug: { eq: $slug }) {
      slug
      title
      date(formatString: $formatString)
      tags {
        name
        slug
      }
      description
      body
      excerpt
      timeToRead
      banner {
        childImageSharp {
          resize(width: 1200, quality: 90) {
            src
          }
        }
      }
    }
  }
`;

type Props = {
  data: {
    post: any;
    [key: string]: any;
  };
};

export default function MinimalBlogCorePost({ data }: Props) {
  const { post } = data;

  return <Post data={{ ...data, post }} />;
}
